<template>
	<router-link
		class="dashboard-card"
		:class="{
			'dashboard-card--disabled': !allowed,
			'dashboard-card--preview': preview,
		}"
		:to="`/education/${categoryId}`"
	>
		<div class="icon__wrapper">
			<img :src="url + '/education/file/' + token" class="icon__image" alt="education-icon" />
		</div>
		<div class="text__wrapper">
			<span class="text__content">
				{{ title }}
			</span>
		</div>
	</router-link>
</template>

<script>
export default {
	name: 'EducationCategoryCard',
	props: {
		categoryId: {
			type: Number,
		},
		title: {
			type: String,
		},
		token: {
			type: String,
		},
		allowed: {
			type: Boolean,
		},
		preview: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			url: process.env.VUE_APP_URL,
		};
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables';

.dashboard-card {
	width: 220px;
	background: #1d2024;
	border: 1px solid rgba(255, 255, 255, 0.1);
	box-sizing: border-box;
	border-radius: 6px;
	display: block;
	margin: 10px;
	cursor: pointer;
	overflow: hidden;

	@include transition(background 0.3s ease);

	&--disabled {
		pointer-events: none;
		opacity: 0.35;
	}

	&--preview {
		pointer-events: none;
		margin-top: 0;
		margin-left: 0;
	}

	@include max-screen($md) {
		width: 100%;
		height: auto;
		margin: 0 0 10px 0;
		display: flex;
		flex-direction: row;
	}

	&:hover {
		background: $cashier-dark;
	}

	.icon {
		&__wrapper {
			height: 220px;
			width: 220px;
			display: flex;
			align-content: center;
			justify-content: center;
			align-items: center;

			@include max-screen($md) {
				height: 60px;
				width: 60px;
				align-items: center;
			}
		}

		&__image {
			height: 100%;
			object-fit: contain;
		}
	}

	.text {
		&__wrapper {
			flex: 1;
			height: 60px;
			background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%), #2a2e31;
			display: flex;
			align-items: center;
			padding: 0 0.5rem;
			border-radius: 0px 0px 5px 5px;

			@include max-screen($md) {
				border-radius: 0px 5px 5px 0px;
			}
		}

		&__content {
			width: 100%;
			white-space: nowrap;
			font-weight: bold;
			font-size: 16px;
			line-height: 2.3;
			text-overflow: ellipsis;
			text-align: center;
			overflow: hidden;

			@include max-screen($md) {
				padding-left: 20px;
				text-align: start;
			}
		}
	}
}
</style>
